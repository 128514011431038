import React from "react";
import ResetSuccess  from "~sections/Account/ResetSuccess";
import { PageWrapper } from "~components/Core";

const header = {
  headerClasses: "d-none",
  containerFluid:false,
}

export default function ResetSuccessPage() {
  return (
    <PageWrapper headerConfig={header}>
        <ResetSuccess/>
    </PageWrapper>
  )
}
