import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ResetSuccessForm from './style'
import { Images } from '~data'
export default function ResetSuccess(){
return(
<ResetSuccessForm backgroundColor="#f3f4f6">
  <Container className="position-static">
    <Row className="align-items-center justify-content-center position-static">
      <Col xs="12" className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-xs-10">
          <ResetSuccessForm.Box>
          <ResetSuccessForm.Title as="h2">Password Reset Success</ResetSuccessForm.Title>
          <ResetSuccessForm.Text as="p">Head Back to Your Zenzo App Now to Login.</ResetSuccessForm.Text>
          </ResetSuccessForm.Box>
      </Col>
    </Row>
  </Container>
</ResetSuccessForm>
)
}